import { getEnv } from "./envHelperService";

// export const APP_API: string = getEnv("REACT_APP_API")
export const APP_API: string = getEnv("REACT_APP_API")
export const CLOUD_TYPE: "azure" | "gcp" = getEnv("REACT_APP_CLOUD_TYPE") as ("azure" | "gcp");
// process.env.REACT_APP_CLOUD_TYPE as ("azure" | "gcp") || "gcp";
export const HYPERVISOR_DOMAIN: string = getEnv("REACT_APP_HYPERVISOR_DOMAIN");
// process.env.REACT_APP_HYPERVISOR_DOMAIN as string;
export const FIREBASE_EVENT_CONFIG_JSON_STRING = JSON.stringify({
    apiKey: "AIzaSyCQE8kKiIVFvhs5pdfBVxF6_91hKkaRQ_k",
    authDomain: "faceopen-techolution.firebaseapp.com",
    databaseURL: "https://planogram.firebaseio.com/",
    projectId: "faceopen-techolution",
    storageBucket: "faceopen-techolution.appspot.com",
    messagingSenderId: "246989474877",
    appId: "1:246989474877:web:6b594e62bd8c78d33b2e54",
    measurementId: "G-TN6FG6QKXP"
}) ||
getEnv("REACT_APP_FIREBASE_EVENT_CONFIG_JSON_STRING")

export const SHOW_TRAINING_POD_SIMPLIFICATION_FEATURE = getEnv("SHOW_TRAINING_POD_SIMPLIFICATION_FEATURE");

export const SHOW_AI_ASSISTANT_LABELLING_SIMPLIFICATION_FEATURE = getEnv("SHOW_AI_ASSISTANT_LABELLING_SIMPLIFICATION_FEATURE");

export const DO_NOT_FORCE_MODEL_AND_TEST_COLLECTION_POPUP_SHOWING = getEnv("DO_NOT_FORCE_MODEL_AND_TEST_COLLECTION_POPUP_SHOWING");
export const COPILOT_TEXT_FOR_SIDE_DRAWER: string = getEnv("COPILOT_TEXT_FOR_SIDE_DRAWER")
export const COPILOT_TEXT_FOR_BREADCRUMBS: string = getEnv("COPILOT_TEXT_FOR_BREADCRUMBS")
export const COPILOT_ADD_BUTTON_TITLE: string = getEnv("COPILOT_ADD_BUTTON_TITLE")