import { IModelType } from ".";
import { PromptSearchOperator, ResourceLabelSearchOperator, ResourceNameSearchOperator, ResourcePredictionSearchOperator, ResourceTagSearchOperator } from "../../../features/admin/features/projects/features/models/features/model/features/data/components/TableContent";
import { ITrimmedAudioAnnotation } from "./coPilotResource";
import { ICommonQueryParams, ICommonResponse, ICommonState } from "./common";

export type ResourceImageKeyPoint = {
    created_at?: Date;
    xCoordinate: number;
    yCoordinate: number;
}
export type ResourceFileType={

        filename:string;
        fileUrl:string;
        imageAnnotations: ResourceImageAnnotation[];
        _id:string;
   
}
export type ResourceImageAnnotation = {
    // used for planogram compartment annotation which means to not use this compartment for motion detection
    shouldIgnore?: boolean;
    
    confidenceScore?: number
    startTimeInSeconds?: number,
    endTimeInSeconds?: number,
    label?: string,
    id: string,
    name?: string,
    vertices: {
        id: string,
        name: string,
        x: number,
        y: number
    }[],
    selectedOptions: {
        id: string,
        value: string
    }[],
    created_at?: Date;

    /** only gets set when sending this in annotation component where resource is part of
     * imageAnnotationGroup Model
     */
    groupAnnotationId?: string;

    color?: string;

    isClosed?: boolean;
    productReferenceNo?: string
}

export type ResourceImageGroupAnnotation = {
    id: string,
    name: string,
    imageAnnotations: ResourceImageAnnotation[],
    created_at?: Date
}


export type videoAnnotation = {
  id: string;
  label: string;
  tag: string;
  annotationByExpert?: {
    startTimeInSeconds: number;
    endTimeInSeconds: number;
    approvalStatus?: "approved" | "rejected"
  },
  annotationPrediction?: {
    startTimeInSeconds: number;
    endTimeInSeconds: number;
    isDeleted?: boolean;
    approvalStatus?: "approved" | "rejected"
  } | null,
};

export type ResourceRemarksText = string;

export type ResourceRemarks = {
    text: ResourceRemarksText,
    updated_at: string
}

export type TrimmedAudio = {
    color?: string;
    _id: string,
    startTimeInSeconds: number,
    endTimeInSeconds: number,
    label: string,
    createdAt: string
  }

export type TrimmedText = {
    _id: string,
    startPosition: number,
    endPosition: number,
    label: string,
    createdAt: string,
    isPiiData ?: boolean,
    confidence_score ?: number,
    tag ?: string
}

export type FileMetaData={
    width: number,
    height: number,
    size ?: number
}

export enum ResourceFields {
    aiAssistantLastCalledDetails="aiAssistantLastCalledDetails",
    aiAssistantLastCalledDetails_calledAt="calledAt"
}

export type PredictionStatusLogic = "label" | "labelAndErrorDeviation"

export interface WordMetadata {
    characterIndex: number;
    timeFrameInAudio: {
      startInMilliSeconds: number;
      endInMilliSeconds?: number;
    };
    word: string;
  }
  

/**
 * Data
 */
export interface IData {
    created_at: string;
    updated_at: string;
    csv: string;
    draw: string;
    resource: string;
    remarks?: ResourceRemarks;
    label: string;
    parentResourceId?: string;
    model: {
        headers: string;
        name: string;
        _id: string;
        project: string;
        type: IModelType;
    };
    files?:ResourceFileType[];
    close?:number;
    status: ResourceStatus;
    _id: string;
    tag: string;
    dataBoost: string;
    prediction: string;
    confidence_score: string;
    filename: string;
    dataSetCollections: ResourceDataSetCollection[];
    statusLastModifiedAt: string;
    changesMade: {
        initiatedByEntity?: {id: string, type: string};
        _id: string,
        change: string,
        created_at: string,
        initiatedBy: {
            emailId: string,
            _id: string
        }
    }[];
    audioTranscription?:string;
    audioTranscriptionBeforeCorrection?: string;
    audioTranscriptionWordsMetadatas?:WordMetadata[];
    /** used only for resources which are part of prompt models */
    ansOfPrompt: string;
    prompt: string;

    /** used only for resources which are part of audio models */
    trimmedAudios?: TrimmedAudio[]

    /** used only for resources which are part of textAnnotation models */
    trimmedTexts?: TrimmedText[]

    /** used only for resources which are part of DLP models */
    isPiiData ?: boolean;

    /** used only for resources which are part of forecasting Models */
    predictedCount?: number;

    /** used only for resources which are part of forecasting Models */
    cv_aiCount?: number;

    /** used only for resources which are part of forecasting Models */
    itemsSoldCount?: number;

    /** used only for resources which are part of forecasting Models */
    itemsDiscardedCount?: number;

    /** used only for resources which are part of forecasting Models */
    itemsAddCount?: number;

    /** used only for resources which are part of forecasting Models */
    videoDurationInSeconds?: number;

    /** used only for resources which are part of forecasting Models */
    forecastDateTime?: string;

    /** used only for resources which are part of imageAnnotation Models */
    imageAnnotations?: ResourceImageAnnotation[];

    /** used only for resources which are part of imageGroupAnnotation Models */
    imageGroupAnnotations?: ResourceImageGroupAnnotation[];

    /** used only for resources which are part of videoAnnotation Models */
    videoAnnotations?: videoAnnotation[];
    arevideoAnnotationsBeingUpdatedInDB?: boolean;

    
    /** used only for resources which are part of videoAnnotation Models */
    expertFeedbackCount: number;

    /**
     * used only when updating imageKeyPoints in DB
     */
    areImageKeyPointsBeingUpdatedInUI?: boolean;

    isResourceRemarksBeingUpdatedInDB?: boolean;

    warmerName?: string;
    additionalResources: AdditionalResource[];

    // used only for typescript fix
    confidenceScore?: number

    // usec only for Globus Screw Project where Original Image size is around or more than 50MB
    resource500WUrl?: string

    // currently will be sent only for image related model resources
    resourceMetaData?: FileMetaData;
    
    // currently will be sent only for image related model resources
    resource500WMetaData?: FileMetaData;

    // applicable for textFile model type
    textFileContent?: string
    
    // applicable for textFile model type
    isFetchingTextFileContents?: boolean

    aiAssistantLastCalledDetails?: {
        calledAt?: string
    }
    // used only for Book Translation model
    textOriginalLanguage?: string

    // used only for Book Translation model
    textTranslatedLanguage?: string

    // used only for Book Translation model
    authorName?: string

    // used only for Book Translation model
    paragraph?: {
        sequenceNumber: Number
    }

    // used only for Book Translation model
    chapter?: {
        sequenceNumber: Number
    }

    // used only for Book Translation model
    textOriginalLanguageSentences?: ITextOriginalLanguageSentence[]

    // used only for Book Translation model
    textTranslatedLanguageSentences?: ITextTranslatedLanguageSentence[]

    // used for audio model only
    spectrogram?: string
    trimmedAudioAnnotation?: ITrimmedAudioAnnotation[]
    spectrogramFileName?: string

    // video classification same hand information for Owen & Minor
    handsData: {
          didUserFoundSameHandHavingMultipleIds: Boolean,
          hands: {ids: string}[]
    },
      // video classification same hand information for Owen & Minor ends    


    // used in textComparison task type
    textDifferencePercentage?: number,
    textsDifferences?: {
        // string character 
        value: string,

        inText: "one" | "two",

        highlightColor?: string,

        characterIndexPosition: number
    }[]
    textOne?: string,
    textTwo?: string,
    // used in textComparison task type ends

    metadataFileName?: string
}

export interface ITextOriginalLanguageSentence {
    sentenceId: string,
    sentence: {
        text: string,
        predictedText?: string
    },
    sequenceNumber: number,
    confidenceScore?: number,
    isApprovedByUser?: boolean
}

export interface ITextTranslatedLanguageSentence {
    sentenceId: string,
    predictedSentence: {
        text: string
    },
    sentence: {
        text: string,
        englishTranslatedText: string,
        predictedText?: string
    },
    connectedWithOriginalLanguageTextSentenceIdsPredicted: 
        {
            sentenceId: string
        }[]
    ,
    connectedWithOriginalLanguageTextSentenceIds: 
        {
            sentenceId: string
        }[]
    ,
    sequenceNumber: number,
    isApprovedByUser: boolean,
    confidenceScore: 100
}

export interface DataSetCollectionQueryParams extends TableDataQueryParams {
  model: string;
  status: string;
}

export interface UpdateAllResourcesQueryParam {
    status: ResourceStatus;
    resourceUpdateFilterQueryParams: DataSetCollectionQueryParams;
}
export interface DeleteBulkResourcesQueryParam {
    status: ResourceStatus;
    resourceDeleteFilterQueryParams: DataSetCollectionQueryParams;
}

export interface TableDataQueryParams {
  sortOrder: SortOrder;
  sortField: DataSortField;
  promptRegex: DataTablePromptSearchValue;
  nameRegex: DataTableResourceNameSearchValue;
  tagRegex: DataTableResourceTagSearchValue;
  includeOnlyMispredictedParagraphs:IncludeOnlyMispredictedParagraphsParamType,
  excludeMispredictedParagraphs:ExcludeMispredictedParagraphsParamType,
  labelRegex: DataTableResourceLabelSearchValue;
  predictionRegex: DataTableResourcePredictionSearchValue
  includeOnlyMispredictedRows: IncludeOnlyMispredictedRowsQueryParamType;
  resourceConfidenceScoreMinValue: number;
  resourceConfidenceScoreMaxValue: number;
    resourceStatusLastModifiedDateMinValue: string;
  resourceStatusLastModifiedDateMaxValue: string;
  resourceDataSetCollectionIds: string[];
  resourcesWhichAreNotPartOfAnyDataSetCollection: ResourcesWhichAreNotPartOfAnyDataSetCollectionQueryParamType;
  forecastDateMinValue: string;
  forecastDateMaxValue: string;
  limit: string;
}

export interface AdditionalResource{
    itemsSoldCount: number;
    itemsDiscardedCount: number;
    itemsAddCount: number;
    videoDurationInSeconds?: number;
    _id: string;
    filename: string;
    warmerName?: string;
    resource: string;
}

export type ResourceDataSetCollection = {
    _id: string,
    name: string
}
export interface IDataUpdatePayload {
    id?: string[];
    status?: ResourceStatus;
    label?: string;
    prediction?: string;
    tag?: string;
    dataBoost?: number;
    resource?: string;
    ansOfPrompt?: string;
    fileId?:string;
    remarks?: string;
    resourceId?:string;
    csv?: string;
    /** used only for resources which are part of imageAnnotation Models */
    imageAnnotations?: ResourceImageAnnotation[];

    /** used only for resources which are part of video annoation Models */
    videoAnnotations?: videoAnnotation[];

    /** used only for resources which are part of imageGroupAnnotation Models */
    imageGroupAnnotations?: ResourceImageGroupAnnotation[];

     /** used only for resources which are part of forecasting Models */
     itemsSoldCount?: number;

     /** used only for resources which are part of forecasting Models */
     itemsDiscardedCount?: number;

     /** used only for resources which are part of Book Translation Models */
     textTranslatedLanguageSentences?: ITextTranslatedLanguageSentence []
     textOriginalLanguageSentences?: ITextOriginalLanguageSentence []
 
     /** used only for resources which are part of forecasting Models */
     itemsAddCount?: number;
     additionalResourceIdstoFilter?: string[],
     additionalResourcePropertiesToUpdate?: {
        [key: string]: string
    } 
    trimmedAudioAnnotation?: any []

    // video classification same hand information for Owen & Minor
    handsData?: {
        didUserFoundSameHandHavingMultipleIds: Boolean | undefined,
        hands: {ids: string}[]
  }
    // video classification same hand information for Owen & Minor ends        

    // audioTextTranscription start
    audioTranscription?: string,
    audioTranscriptionBeforeCorrection?: string
    // audioTextTranscriptiion end
}

export type GetResourceFromDBByIdAPIReqPayload = {
    resourceId: string,
    resourceSelectQuery?: string
}

/*----------------------------------------------*/

/**
 * Actions Payload
 */

 export type FETCH_DATA_SET_COLLECTIONS_FOR_FILTER_REQUEST_ACTION_PAYLOAD = null
 export type FETCH_DATA_SET_COLLECTIONS_FOR_FILTER_SUCCESS_ACTION_PAYLOAD = {
     dataSetCollections: ResourceDataSetCollection[]
 }
 export type ADD_DATA_SET_COLLECTION_IN_DATA_SET_COLLECTIONS_FILTER_ACTION_PAYLOAD = {
     dataSetCollectionToAdd: ResourceDataSetCollection
 }

 export type IS_EDIT_TEXT_SELECTED_ACTION_PAYLOAD = boolean;

export type UPDATE_RESOURCE_IMAGE_ANNOTATIONS_REQUEST_Action_Payload = {
    resourceId: string,
}

export type UPDATE_RESOURCE_IMAGE_ANNOTATIONS_Action_Payload = {
    resourceId: string,
    imageAnnotations: ResourceImageAnnotation[]
}
export type UPDATE_RESOURCE_MULTIPLE_IMAGE_ANNOTATIONS_Action_Payload = {
    resourceId: string,
    fileId:string,
    imageAnnotations: ResourceImageAnnotation[]
}

export type UPDATE_RESOURCE_VIDEO_ANNOTATIONS_Action_Payload = {
    resourceId: string,
    videoAnnotations: videoAnnotation[]
}
export type UPDATE_RESOURCE_IMAGE_GROUP_ANNOTATIONS_REQUEST_Action_Payload = {
    resourceId: string,
}
export type UPDATE_RESOURCE_IMAGE_GROUP_ANNOTATIONS_Action_Payload = {
    resourceId: string,
    imageGroupAnnotations: ResourceImageGroupAnnotation[]
}

export type UPDATE_RESOURCE_REMARKS_REQUEST_Action_Payload = {
    resourceId: string,
}
export type UPDATE_RESOURCE_REMARKS_Action_Payload = {
    resourceId: string,
    resourceRemarks: ResourceRemarks
}
export type UPDATE_RESOURCE_PROMPT_Action_Payload = {
    resourceId: string,
    resource?: string;
    ansOfPrompt?: string;
}
export type UPDATE_DATA_REQUEST_Action_Payload = {
    status: ResourceStatus;
    id: string[];
}
export type UPDATE_DATA_SUCCESS_Action_Payload = {
    status: ResourceStatus;
    id: string[];
}
/*----------------------------------------------*/


/**
 * Params
 */
export type StringSearchOperator = 'contains' | 'equals' | 'starts with' | 'ends with';
export type DataSetSortField = "name" | "description" | "created_at";
export type DataSortField = 'prompt' | 'resource' | 'tag' | 'statusLastModifiedAt' | 'label' | 'confidence_score' | 'forecastDateTime';
export type SortOrder = 'ascending' | 'descending';
export const SortOrderDefaultValue: SortOrder = 'descending';
export const DataSortFieldDefaultValue: DataSortField = 'statusLastModifiedAt';
export const DataTableNameSearchOperatorDefaultValue: StringSearchOperator = 'equals';
// export const DataTableNameSearchValueQueryParam = 'nameSearchValue';
// export const DataTableNameSearchOperatorQueryParam = 'nameSearchOperator';
// export const DataTableDescriptionSearchValueQueryParam = 'descriptionSearchValue';
// export const DataTableDescriptionSearchOperatorQueryParam = 'descriptionSearchOperator';
// export const DataTableCreatedDateMinValueQueryParam = 'createdDateMinValue';
// export const DataTableCreatedDateMaxValueQueryParam = 'createdDateMaxValue';

export type ModelCollectionSortField = "created_at" | "version" | "accuracy" | "status" | "modelArchitecture";

export type TestCollectionSortField ="version" | "dataset" | "status";

export type DataTableResourceNameSearchValue = string;
export const DataTableResourceNameSearchValueQueryParam = 'resourceNameSearchValue';
export type DataTablePromptSearchValue = string;
export const DataTablePromptSearchValueQueryParam = 'PromptSearchValue';
export type IncludeOnlyMispredictedParagraphsParamType="true" | "false"
export const IncludeOnlyMispredictedParagraphsParam="includeOnlyMispredictedParagraphs"
export type ExcludeMispredictedParagraphsParamType ="true" | "false"
export const ExcludeMispredictedParagraphsQueryParam="excludeMispredictedParagraphs"
export const DataTableResourceNameSearchOperatorQueryParam = 'resourceNameSearchOperator';
export const DataTableResourceNameSearchOperatorDefaultValue: ResourceNameSearchOperator = 'equals';
export const DataTablePromptSearchOperatorQueryParam = 'promptSearchOperator';
export const DataTablePromptSearchOperatorDefaultValue: PromptSearchOperator = 'equals';

export type DataTableResourceTagSearchValue = string;
export const DataTableResourceTagSearchValueQueryParam = 'resourceTagSearchValue';
export const DataTableResourceTagSearchOperatorQueryParam = 'resourceTagSearchOperator';
export const DataTableResourceTagSearchOperatorDefaultValue: ResourceTagSearchOperator = 'equals';

export type DataTableResourceLabelSearchValue = Array<string>;
export const DataTableResourceLabelSearchValueQueryParam = 'resourceLabelSearchValue';
export const DataTableResourceLabelSearchOperatorQueryParam = 'resourceLabelSearchOperator';
export const DataTableResourceLabelSearchOperatorDefaultValue: ResourceLabelSearchOperator = 'equals';
export type IncludeOnlyMispredictedRowsQueryParamType = 'true' | 'false';
export const IncludeOnlyMispredictedRowsQueryParam = 'includeOnlyMispredictedRows';
export type DataTableResourcePredictionSearchValue = Array<string>;
export const DataTableResourcePredictionSearchValueQueryParam = 'resourcePredictionSearchValue';
export const DataTableResourcePredictionSearchOperatorQueryParam = 'resourcePredictionSearchOperator';
export const DataTableResourcePredictionSearchOperatorDefaultValue: ResourcePredictionSearchOperator = 'equals';

export const resourceDataSetCollectionIdQueryParam = 'resourceDataSetCollectionIds';

export const resourceConfidenceScoreDefaultMinValue = 0;
export const DataTableResourceConfidenceScoreMinValueQueryParam = 'resourceConfidenceScoreMinValue';

export const resourceConfidenceScoreDefaultMaxValue = 100;
export const DataTableResourceConfidenceScoreMaxValueQueryParam = 'resourceConfidenceScoreMaxValue';

export const DataTableResourceStatusLastModifiedDateMinValueQueryParam = 'resourceStatusLastModifiedDateMinValue';
export const DataTableResourceStatusLastModifiedDateMaxValueQueryParam = 'resourceStatusLastModifiedDateMaxValue';

export const ForecastDateMinValueQueryParam = 'forecastDateMinValue';
export const ForecastDateMaxValueQueryParam = 'forecastDateMaxValue';

export type ResourceStatus = 'backlog' | 'approval' | 'approved' | 'rejected' | 'deleted' | 'collections';

export type ResourcesWhichAreNotPartOfAnyDataSetCollectionQueryParamType = 'true' | '';
export const DataTableResourcesWhichAreNotPartOfAnyDataSetCollectionQueryParam = 'resourcesWhichAreNotPartOfAnyDataSetCollection';

export interface ModelCollectionTableSortParams {
    order : SortOrder;
    field : string;
}

export interface ModelCollectionTableFilterParms{
    filter: {
        version: {
            searchValue : string[],
            menuOpen: boolean
        },
        status: {
          searchValue: string[],
          menuOpen: boolean
        },
        trainingDataSetCollectionNames: {
          searchValue: string[],
          menuOpen: boolean
        },
        architectures: {
          searchValue: string[],
          menuOpen: boolean
        },
        accuracy: {
          accuracyStatus: string[],
          minValue: string,
          maxValue: string,
          menuOpen: boolean
        },
        createdDate: {
            minValue: string,
            maxValue: string,
            menuOpen: boolean
        }
      }
}

export interface TestCollectionTableSortParams {
    order : SortOrder;
    field : string;
}

export interface TestCollectionTableFilterParms{
    filter: {
        version: {
            searchValue : string[],
            menuOpen: boolean
        },
        status: {
          searchValue: string[],
          menuOpen: boolean
        },
        models: {
          searchValue: string[],
          menuOpen: boolean
        },
        dataSets: {
          searchValue: string[],
          menuOpen: boolean
        }
      }
}
export interface IDataQueryParams extends ICommonQueryParams {
    model?: string;
    status?: ResourceStatus;
    ids?: string;
    sortOrder?: SortOrder;
    sortField?: DataSortField;
    promptRegex?: DataTablePromptSearchValue;
    nameRegex?: DataTableResourceNameSearchValue;
    tagRegex?: DataTableResourceTagSearchValue;
    labelRegex?: DataTableResourceLabelSearchValue;
    predictionRegex ?: DataTableResourcePredictionSearchValue;
    includeOnlyMispredictedRows?: IncludeOnlyMispredictedRowsQueryParamType;
    resourceConfidenceScoreMinValue?: number;
    resourceConfidenceScoreMaxValue?: number;
    resourceStatusLastModifiedDateMinValue?: string;
    resourceStatusLastModifiedDateMaxValue?: string;
    forecastDateMinValue?: string;
    forecastDateMaxValue?: string;
    resourceDataSetCollectionIds?: string;
    resourcesWhichAreNotPartOfAnyDataSetCollection?: ResourcesWhichAreNotPartOfAnyDataSetCollectionQueryParamType
    resourceSelectQuery?: string
}

export interface IUniqueLabelAndPrediction {
    modelId : string;
    status ?: ResourceStatus;
}
export interface IUniqueLabelAndPredictionResponse {
    uniqueLabels  : string[];
    uniquePredictions ?: string[];
}
/*----------------------------------------------*/

/**
 * Responses
 */
export interface IDataResponse extends ICommonResponse {
    resources: IData[];
}
export type DataView = "grid-view" | "list-view";

/*----------------------------------------------*/

/**
 * Redux
 */
export interface IDataState extends ICommonState {
    data: IDataResponse;
    // drawerData?: IData | null;
    approvedWaitingList: string[];
    approvalWaitingList: string[];
    rejectedWaitingList: string[];
    deletedWaitingList: string[];
    // selected?: IData;
    selected?: string; // dataId
    selectedItems: string[]; // array of resourceIds
    
    /** resources which are part of api request payload  */
    resourcesWhoseDataSetCollectionsAreBeingUpdatedInDB: string[];

    dataSetCollectionsForFilter: ResourceDataSetCollection[];
    dataSetCollectionsBeingFetchedForFilter: boolean;


    /**
     * When edit text component (tag/label input field) is clicked, this 
     * will be set as true, otherwise should be set as false
     */
    // isEditTextSelected: boolean
    dataView?: DataView;
    shouldBlockDataTableKeyboardEvents: boolean;

}
/*----------------------------------------------*/

/**
 * Action Payloads
 */
export type GetDataSuccessForSelectingAllResourcesMatchingFilterActionPayload = {
    resources: IData[],
}
export type UpdateItemsCountPayload = {count: number, resourceId: string, videoId: string, videoDurationInSeconds:  number }
/*----------------------------------------------*/