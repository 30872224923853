import { useState, useCallback, useMemo, ReactElement } from "react";
import { useURLQuery } from "../../../../../../../hooks/useURLQuery";
import { ModelService } from "../../../../../../../services";
import { ModelGroupUI } from "../components/modelGroupList/modelGroupList";
import ModelListContext from "./modelist.context";
import {useRef} from 'react';

type Props = {
  children: ReactElement;
};

export default function ModelistContextComponent(props: Props) {
  const [modelGroups, setModelGroups] = useState<ModelGroupUI[]>([]);

  // const urlSearchParams = useURLQuery();
  const urlSearchParams = new URLSearchParams(window.location.search);
  const modelGroupsRef = useRef(modelGroups);
  modelGroupsRef.current = modelGroups;

  const setModelGroupsRef = useCallback(
    (modelGroupsToSet: typeof modelGroups) => {
      modelGroupsRef.current = modelGroupsToSet;
      setModelGroups([...modelGroupsToSet])
    },
    [],
  );

  const projectId = urlSearchParams.get("project") || "";

  const [isFetchingModelGroupsFromDB, setIsFetchingModelGroupsFromDB] =
    useState(true);

  const [isInProjectModelsPage, setIsInProjectModelsPage] = useState(false);

  const fetchModelGroupsFromDB = useCallback(async () => {
    setIsFetchingModelGroupsFromDB(true);

    let modelGroupsToSet: ModelGroupUI[] = [];
    if(!projectId){
      return;
    }

    const modelGroupsSavedInDB = await ModelService.getModelGroups({
      projectId: projectId,
      getModels: "true",
      collectionsExist : true,
    });

    for (const modelGroupSavedInDB of modelGroupsSavedInDB) {
      modelGroupsToSet.push({
        ...modelGroupSavedInDB,
        models: modelGroupSavedInDB.models || [],
        nodes: modelGroupSavedInDB.nodes.map((node) => {
          return {
            ...node,
            data: {},
            type: "modelNode",
          };
        }),
        editedNodes: modelGroupSavedInDB.nodes.map((node) => {
          return {
            ...node,
            data: {},
            type: "modelNode",
          };
        }),
        editedEdges: modelGroupSavedInDB.edges,
        isDirty: false,
        mode: "view",
      });
    }

    setModelGroups([...modelGroupsToSet]);

    setIsFetchingModelGroupsFromDB(false);
  }, [projectId]);

  const removeModelFromModelGroups = useCallback(
    (modelIdOfModelToRemove: string) => {
      setModelGroups((oldModelGroups) => {
        for (
          let modelGroupIndex = 0;
          modelGroupIndex < oldModelGroups.length;
          modelGroupIndex++
        ) {
          const oldModelGroup = oldModelGroups[modelGroupIndex];
          if (oldModelGroup.nodes?.length > 0) {
            for (
              let nodeIndex = 0;
              nodeIndex < oldModelGroup.nodes.length;
              nodeIndex++
            ) {
              const node = oldModelGroup.nodes[nodeIndex];
              if (node.id === modelIdOfModelToRemove) {
                oldModelGroup.nodes.splice(nodeIndex, 1);
                nodeIndex -= 1;
              }
            }
          }
          if (oldModelGroup.editedNodes?.length > 0) {
            for (
              let nodeIndex = 0;
              nodeIndex < oldModelGroup.editedNodes.length;
              nodeIndex++
            ) {
              const node = oldModelGroup.editedNodes[nodeIndex];
              if (node.id === modelIdOfModelToRemove) {
                oldModelGroup.editedNodes.splice(nodeIndex, 1);
                nodeIndex -= 1;
              }
            }
          }
          if (oldModelGroup.edges?.length > 0) {
            for (
              let edgeIndex = 0;
              edgeIndex < oldModelGroup.edges.length;
              edgeIndex++
            ) {
              const edge = oldModelGroup.edges[edgeIndex];
              if (
                edge.source === modelIdOfModelToRemove ||
                edge.target === modelIdOfModelToRemove
              ) {
                oldModelGroup.edges.splice(edgeIndex, 1);
                edgeIndex -= 1;
              }
            }
          }
          if (oldModelGroup.editedEdges?.length > 0) {
            for (
              let edgeIndex = 0;
              edgeIndex < oldModelGroup.editedEdges.length;
              edgeIndex++
            ) {
              const edge = oldModelGroup.editedEdges[edgeIndex];
              if (
                edge.source === modelIdOfModelToRemove ||
                edge.target === modelIdOfModelToRemove
              ) {
                oldModelGroup.editedEdges.splice(edgeIndex, 1);
                edgeIndex -= 1;
              }
            }
          }
        }

        return [...oldModelGroups];
      })
    },
    []
  );  

  const modelListContextValue = useMemo(() => {
    return {
      modelGroups: modelGroups,
      setModelGroups: setModelGroups,
      isFetchingModelGroupsFromDB: isFetchingModelGroupsFromDB,
      fetchModelGroupsFromDB: fetchModelGroupsFromDB,
      removeModelFromModelGroups,
      setIsInProjectModelsPage,
      isInProjectModelsPage,
      modelGroupsRef,
      setModelGroupsRef
    };
  }, [fetchModelGroupsFromDB, isFetchingModelGroupsFromDB, isInProjectModelsPage, modelGroups, removeModelFromModelGroups, setModelGroupsRef]);

  return (
    <ModelListContext.Provider
      value={modelListContextValue}
    >
      {props.children}
    </ModelListContext.Provider>
  );
}
