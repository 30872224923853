import { createContext } from "react";
import { ModelGroupUI } from "../components/modelGroupList/modelGroupList";

// @ts-ignore
const ModelListContext = createContext<{
  modelGroups: ModelGroupUI[];
  setModelGroups: React.Dispatch<React.SetStateAction<ModelGroupUI[]>>;
  isInProjectModelsPage: boolean,
  setIsInProjectModelsPage: React.Dispatch<React.SetStateAction<boolean>>;
  modelGroupsRef: React.MutableRefObject<ModelGroupUI[]>;
  setModelGroupsRef: (modelGroupsToSet: ModelGroupUI[]) => void;
  isFetchingModelGroupsFromDB: boolean;
  fetchModelGroupsFromDB: () => Promise<void>;
  removeModelFromModelGroups: (modelIdOfModelToRemove: string) => void;
}>();

export default ModelListContext;
